import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

import { StyledIndicator, StyledIndicatorWrapper } from '../Styled';

export const toDataUrl = (Mask: () => JSX.Element) =>
  `data:image/svg+xml,${encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Mask />))}`;

export const carouselSlidesToShow = (isHero: boolean, isLogo: boolean) => {
  if (isHero) return 1;
  if (isLogo) return 4;
  return 3;
};

export const carouselResponsiveSettings = (isHero: boolean) => {
  if (!isHero) {
    return [
      {
        breakpoint: 1350,
        settings: {
          className: 'carousel-medium',
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          className: 'carousel-small',
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ];
  }
  return undefined;
};

export const shouldBeInfinite = (contentEntries: any[], isHero: boolean, isLogo: boolean) => {
  if (isHero && contentEntries.length > 1) return true;
  if (isLogo && contentEntries.length > 4) return true;
  if (!isHero && !isLogo && contentEntries.length > 3) return true;
  return false;
};

export const getSliderSettings = (
  isHero: boolean,
  isLogo: boolean,
  maskPresent?: boolean,
  controlHeight?: string,
  infinite = true,
) => {
  const appendDots = (dots: Array<ReactElement>) => {
    const newDots = dots.map(dot => {
      if (dot.props['className'] === 'slick-active') {
        return React.cloneElement(dot, {}, <StyledIndicator active isHero={isHero} />);
      }
      return React.cloneElement(dot);
    });
    return (
      <StyledIndicatorWrapper isHero={isHero} maskPresent={maskPresent} controlHeight={controlHeight}>
        {newDots}
      </StyledIndicatorWrapper>
    );
  };

  return {
    appendDots,
    arrows: true,
    centerPadding: '0px',
    className: isHero ? 'carousel-fullWidth' : 'carousel-large',
    customPaging: () => <StyledIndicator isHero={isHero} />,
    dots: true,
    infinite,
    responsive: carouselResponsiveSettings(isHero),
    slidesToScroll: carouselSlidesToShow(isHero, isLogo),
    slidesToShow: carouselSlidesToShow(isHero, isLogo),
    speed: 500,
  };
};
