import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../utils/Media';

export const StyledBox = styled.div`
  background-color: ${props => props.theme.styles.blockquote.backgroundColor};
  color: ${props => props.theme.styles.blockquote.color};

  ${Media({
    paddingBottom: ['3rem', null, '3.5rem'],
    paddingLeft: ['2rem', null, '3.5rem'],
    paddingRight: ['1.25rem', null, '3.5rem'],
    paddingTop: ['3rem', null, '3.5rem'],
  })}
`;

export const StyledQuote = styled(Text)`
  font-weight: ${props => props.theme.styles.blockquote.fontWeight};
  max-width: 815px;
  text-transform: ${props => props.theme.styles.blockquote.textTransform};
  font-style: ${props => props.theme.styles.blockquote.fontStyle};

  &::before,
  &::after {
    content: '"';
  }

  > p {
    display: inline;
    font-size: ${props => props.theme.styles.blockquote.fontSize ?? 'inherit'};
    line-height: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-transform: inherit;
    margin-bottom: unset;
  }

  ${({ theme }) =>
    Media({
      fontSize: ['1.5rem', null, null, '2rem'],
      lineHeight: ['1.33', null, null, '1.25'],
      marginBottom: theme.styles.blockquote.marginBottom ?? ['1.625rem', null, null, '2rem'],
      marginRight: ['60px', null, null, '150px'],
    })}
`;

export const StyledSeparator = styled.span`
  display: block;
  width: 24px;
  border-top: 1px solid ${props => props.theme.styles.blockquote.color};
  margin-bottom: 1rem;
`;

export const StyledAuthor = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.57;
  font-weight: bold;
  text-transform: ${props => props.theme.styles.blockquote.authorTextTransform};
`;

export const StyledInfo = styled(Text)`
  font-size: 0.875rem;
  line-height: 1.57;
  text-transform: ${props => props.theme.styles.blockquote.infoTextTransform};
`;

export const StyledImage = styled(Box)`
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  background-size: cover;
  margin-right: 24px;
`;

export const StyledAuthorWrapper = styled.div`
  display: flex;

  ${Media({
    marginRight: ['60px', null, null, '150px'],
  })}
`;

export const StyledAuthorContentWrapper = styled.div`
  flex: 1;
`;
