/* eslint-disable sort-keys-fix/sort-keys-fix */
import Logo from './Logo';

// BASICS
export const baseColors = {
  beige: '#EEE5DB',
  black: '#000',
  gray: [
    '#fff', // 0 index
    '#f8f8f8',
    '#dedede',
    '#979797',
    '#3d3d3d',
  ],
  white: '#fff',
  greenDark: '#02492E',
  yellowDark: '#FAB526',
  greenDarker: '#153225',
  yellowLight: '#EFD57B',
  yellowGreen: '#BCA24E',
  beigeDark: '#BDB7AC',
};

export const colors = {
  ...baseColors,
  background: baseColors.white,
  backgroundLight: baseColors.beige,
  buttonGreen: baseColors.greenDark,
  primary: baseColors.greenDark,
  primaryBeige: baseColors.beige,
  text: baseColors.greenDark,
};

export const space = [0, 0.25, 0.5, 1, 1.5, 3].map(n => `${n}rem`);

export const breakpoints = ['576px', '768px', '992px'];

export const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'Poppins, sans-serif',
  hightlight: 'Poppins, sans-serif',
  quotes: 'Poppins, sans-serif',
};

export const fontWeights = {
  body: 400,
  bold: 700,
  display: 300,
  heading: 500,
  headingAlt: 600,
  light: 300,
  normal: 400,
  medium: 500,
};

export const fontSizes = {
  0: '0.75rem', // 12px
  1: '0.875rem', // 14px
  2: '1rem', // 16px
  3: '1.125rem', // 18px
  4: '1.1875rem', // 19px
  5: '1.25rem', // 20px
  6: '1.3125rem', // 21px
  7: '1.5rem', // 24px
  8: '1.5625rem', // 25px
  9: '1.75rem', // 28px
  10: '1.875rem', // 30px
  11: '1.9375rem', // 31px
  12: '2.1875rem', // 35px
  13: '2.25rem', // 36px
  14: '2.5rem', // 40px
};

export const lineHeights = {
  body: 1.5,
  heading: 1.5,
  paragraph: 1.75,
};

export const sizes = {
  // container widths
  sm: '540px',
  md: '720px',
  lg: '1150px',
  xl: '1440px',
};

export const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
};

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
};

const heading = {
  fontFamily: fonts.heading,
  fontWeight: fontWeights.heading,
  lineHeight: lineHeights.heading,
};

const display = {
  fontWeight: fontWeights.display,
  lineHeight: lineHeights.heading,
};

// variants
const typeStyles = {
  display,
  heading,
};

export const text = {
  caps: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
  heading: {
    fontFamily: fonts.heading,
    fontWeight: fontWeights.heading,
    lineHeight: lineHeights.heading,
    textTransform: 'uppercase',
  },
};

export const styles = {
  root: {
    backgroundColor: colors.white,
    fontFamily: fonts.body,
    fontWeight: fontWeights.body,
    lineHeight: lineHeights.body,
  },
  a: {
    color: `${colors.greenDark} !important`,
    textDecoration: 'underline',
    ':hover': {
      fontWeight: fontWeights.medium,
      textDecoration: 'solid underline 1px',
      textUnderlineOffset: '6px',
    },
  },
  blockquote: {
    backgroundColor: colors.yellowLight,
    color: '#153225',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '1.3rem',
    textTransform: 'none',
    infoTextTransform: 'uppercase',
    authorTextTransform: 'uppercase',
    marginBottom: ['1.625rem', null, null, '4rem'],
  },
  h1: {
    ...heading,
    fontSize: { desktop: fontSizes[14], mobile: fontSizes[10], tablet: fontSizes[12] },
    lineHeight: { desktop: '3.75rem', mobile: '4.75rem', tablet: '4.75rem' },
  },
  h2: {
    ...heading,
    fontSize: { desktop: fontSizes[13], mobile: fontSizes[8], tablet: fontSizes[9] },
    lineHeight: { desktop: '3.375rem', mobile: '3.375rem', tablet: '3.375rem' },
    fontWeight: 'medium',
  },
  h3: {
    ...heading,
    fontSize: { desktop: fontSizes[9], mobile: fontSizes[5], tablet: fontSizes[7] },
    lineHeight: { desktop: '2.625rem', mobile: '2.625rem', tablet: '2.625rem' },
    fontWeight: 'medium',
  },
  h4: {
    fontSize: { desktop: fontSizes[6], mobile: fontSizes[2], tablet: fontSizes[4] },
    lineHeight: { desktop: '2rem', mobile: '2rem', tablet: '2rem' },
    fontWeight: 'medium',
  },
  h5: {
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[1], tablet: fontSizes[1] },
    lineHeight: { desktop: '1.5rem', mobile: '1.5rem', tablet: '1.5rem' },
    fontWeight: 'medium',
  },
  highlight: {
    color: colors.greenDark,
  },
  img: {
    height: 'auto',
    maxWidth: '100%',
  },
  inlineCode: {
    color: colors.greenDark,
  },
  li: {
    bulletBorderRadius: '5px',
    bulletColor: colors.greenDark,
    width: '8px',
    height: '8px',
    top: '10px',
  },
  p: {
    fontSize: { desktop: fontSizes[2], mobile: fontSizes[0], tablet: fontSizes[1] },
    lineHeight: lineHeights.paragraph,
    margin: '0',
    marginBottom: '1.7rem',
  },
  rteHighlight: {
    color: colors.greenDark,
    hoverColor: colors.greenDarker,
    isFooter: colors.beige,
    textDecoration: 'underline',
    fontWeight: 600,
  },
  table: {
    borderCollapse: 'collapse',
    color: colors.gray[3],
    marginBottom: '3rem',
    width: '100%',
  },
  td: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    padding: '.75rem',
    verticalAlign: 'top',
  },
  th: {
    borderBottomColor: colors.gray[3],
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderTopColor: colors.gray[3],
    borderTopStyle: 'solid',
    borderTopWidth: '2px',
    padding: '.75rem',
    textAlign: 'inherit',
    verticalAlign: 'bottom',
  },
};

const buttonDefaults = {
  cursor: 'pointer',
  fontFamily: fonts.body,
  fontSize: '18px',
  fontWeight: fontWeights.body,
  minWidth: '112px',
  padding: '10px 25px',
  position: 'relative',
  height: '45px',
  transition: 'all 0.2s ease',
  '&::before': {
    content: 'attr(title)',
    display: 'block',
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    fontWeight: fontWeights.medium,
  },
  '&:hover': {
    fontWeight: fontWeights.medium,
  },
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    border: `1px solid ${colors.greenDarker}`,
    color: colors.greenDarker,
    borderRadius: '0px',
    background: 'transparent',
    '&:hover': {
      backgroundColor: colors.yellowDark,
      color: colors.white,
      border: 'none',
      borderRadius: '0px 15px 0px 0px',
    },
  },
  'primary-with-background': {
    ...buttonDefaults,
    border: `1px solid ${colors.greenDark}`,
    color: colors.greenDark,
    borderRadius: '0px',
    background: 'transparent',
    '&:hover': {
      backgroundColor: colors.greenDark,
      color: colors.white,
      border: 'none',
      borderRadius: '0px 15px 0px 0px',
    },
  },
  secondary: {
    ...buttonDefaults,
    border: `1px solid ${colors.white}`,
    color: colors.white,
    borderRadius: '0px',
    background: 'transparent',
    '&:hover': {
      backgroundColor: colors.yellowDark,
      color: colors.white,
      border: 'none',
      borderRadius: '0px 15px 0px 0px',
    },
  },

  disabled: {
    ...buttonDefaults,
    backgroundColor: colors.beige,
    color: colors.white,
    cursor: 'default',
  },
};

// COMPONENTS
export const adviceCardModule = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
};

export const articleCard = {
  boxShadow: 'none',
};

export const articleDetail = {
  tags: {
    backgroundColor: colors.beige,
    color: colors.greenDarker,
  },
  title: colors.greenDarker,
  introduction: colors.yellowDark,
};

export const articleListing = {
  background: colors.beige,
  filter: colors.greenDarker,
  text: colors.white,
  title: colors.greenDarker,

  mobile: {
    title: colors.greenDarker,
    text: colors.text,
    background: colors.beige,
  },
};

export const cardCtas = {
  arrowColor: colors.yellowDark,
  arrowHoverColor: colors.primary,
  color: colors.yellowDark,
  fontSize: '14px',
  letterSpacing: '0px',
  lineHeight: '14px',
  textTransform: 'normal',
};

export const cards = {
  article: {
    description: colors.text,
    link: {
      color: colors.greenDarker,
      fontWeight: fontWeights.medium,
      textDecoration: 'underline',
    },
    title: {
      color: colors.text,
      fontSize: fontSizes[6],
      fontWeight: fontWeights.bold,
      lineHeight: '2.188rem',
      fontFamily: fonts.heading,
    },
  },
  logo: {
    color: colors.greenDarker,
  },
};

export const carousel = {
  boxShadow: 'none',
  controls: {
    regular: {
      backgroundColor: colors.beige,
      color: colors.greenDarker,
      hover: {
        backgroundColor: colors.yellowDark,
      },
    },
    hero: {
      opacity: '0.8',
      color: colors.greenDarker,
      backgroundColor: colors.beige,
      stroke: colors.greenDarker,
      hover: {
        color: colors.greenDarker,
        backgroundColor: colors.yellowDark,
        stroke: colors.greenDarker,
      },
    },
  },
  indicator: colors.greenDarker,
  link: {
    color: colors.primary,
    '&:hover': {
      color: colors.greenDarker,
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  text: colors.greenDarker,
};

export const categoryCards = {
  backgroundColor: colors.primary,
  titleFontWeight: 'bold',
};

export const categorySection = {
  alignment: 'flex-end',
  background: colors.yellowDark,
  backgroundImage: '/images/category-background.png',
  color: colors.white,
  date: colors.yellowDark,
  dateColor: colors.white,
  heading: {
    color: colors.greenDarker,
    fontWeight: fontWeights.light,
    fontSize: '2.5rem',
  },
  title: {
    fontWeight: fontWeights.light,
    fontSize: {
      mobile: '1rem',
      tablet: '1rem',
      desktop: '1rem',
    },
    lineHeight: {
      mobile: '1.5rem',
      tablet: '1.5rem',
      desktop: '1.5rem',
    },
    wrapper: {
      maxWidth: 'calc(100% - 60px)',
    },
  },
  chevron: {
    fill: colors.beige,
    stroke: colors.greenDark,
    hoverFill: colors.yellowDark,
    hoverStroke: colors.greenDarker,
  },
};

export const columns = {
  imageCard: colors.text,
  imageCardTitle: colors.text,
  infoBlock: {
    background: colors.greenDarker,
    title: colors.white,
    description: colors.white,
  },
};

export const contactForm = {
  heading: {
    color: colors.text,
  },
};

export const contacts = {
  description: colors.greenDarker,
  title: colors.greenDarker,
  titleFontWeight: 'bold',
  office: {
    mapTextColor: colors.primary,
    mapTextFontSize: '14px',
    mapTextFontWeight: 'bold',
    mapTextLetterSpacing: '0px',
    mapTextLineHeight: '2',
    mapTextTransform: 'normal',
    openingHoursFontSize: '16px',
  },
};

export const cookieBanner = {
  backgroundColor: colors.yellowLight,
};

export const ctaBanner = {
  focusImage: {
    backgroundColor: colors.greenDarker,
    color: colors.white,
    titleFontWeight: 'medium',
    titleColor: colors.yellowLight,
    '& ul li::marker': {
      color: colors.yellowLight,
    },
  },
  focusText: {
    applyMask: false,
    backgroundColor: colors.yellowDark,
    color: colors.greenDarker,
    titleColor: colors.greenDarker,
    titleFontWeight: 'medium',
    '& ul li::marker': {
      color: colors.greenDarker,
    },
  },
};

export const ctaGroup = {
  title: colors.yellowDark,
  titleFontWeight: fontWeights.normal,
};

export const customBlockCards = {
  backgroundColor: colors.yellowDark,
  color: colors.greenDarker,
  titleFontWeight: 'medium',
};

export const documentDownload = {
  arrowColor: colors.greenDarker,
  arrowHoverColor: colors.primary,
  color: colors.greenDarker,
  fontWeight: 'bold',
  hovercolor: colors.primary,
};

export const footer = {
  activeColor: colors.yellowLight,
  backgroundColor: colors.yellowLight,
  color: colors.beige,
  minHeight: '160px',
};

export const formInputs = {
  activeColor: colors.primary,
  borderRadius: '4px',
  radioInputBorderRadius: '50%',
  radioSpanBorderRadius: '50%',
  label: {
    fontSizes: ['12px', null, '16px'],
    lineHeights: ['21px', null, '26px'],
  },
};

export const header = {
  activeColor: colors.primary,
  backgroundColor: colors.primaryBeige,
  color: colors.greenDarker,
};

export const headerBottom = {
  activeColor: colors.greenDarker,
  activeFontWeight: '700',
  backgroundColor: colors.white,
  color: colors.greenDarker,
  fontSize: '14px',
  fontWeight: '400',
};

export const heroBannerButton = {
  'No Background': {
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.greenDarker,
    },
    backgroundColor: 'transparent',
    borderColor: colors.white,
    borderStyle: 'solid',
    borderWidth: '1px',
    color: colors.white,
    height: '54px',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  'White Background': {
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: colors.white,
      borderStyle: 'solid',
      borderWidth: '1px',
      color: colors.white,
    },
    backgroundColor: colors.white,
    color: colors.greenDarker,
    height: '54px',
    justifyContent: 'space-between',
    padding: '0px 12px',
    width: 'fit-content',
  },
  arrow: {
    display: 'block',
  },
};

export const heroSection = {
  titleFontWeight: 'light',
};

export const imageAndText = {
  color: colors.greenDarker,
  borderRadius: '0px 100px 0px 0px',
};

export const infoBlock = {
  background: colors.backgroundLight,
  title: colors.text,
  description: colors.text,
  button: {
    backgroundColor: colors.buttonGreen,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.greenDarker,
      textDecoration: 'none',
    },
  },
};

export const infoSection = {
  backgroundColor: colors.yellowDark,
  color: colors.greenDarker,
  title: colors.greenDarker,
};

export const ingredients = {
  backgroundColor: colors.primary,
  color: colors.white,
  hoverColor: colors.white,
};

export const media = {
  backgroundColor: 'transparent',
  titleFontWeight: 'light',
  titleTextTransform: 'normal',
};

export const mobileNavigation = {
  arrowColor: colors.white,
  chevronColor: colors.beigeDark,
};

export const navItems = {
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0px',
  selectedFontWeight: 400,
  textTransform: 'normal',
};

export const newsListing = {
  titleFontWeight: 'bold',
};

export const ownerDetail = {
  tags: {
    backgroundColor: colors.yellowGreen,
    color: colors.white,
  },
  title: colors.greenDarker,
  introduction: colors.yellowDark,
};

export const product = {
  backgroundColor: colors.greenDarker,
  color: colors.white,
};

export const productListing = {
  titleFontWeight: 'bold',
  color: colors.greenDark,
};

export const salesPerson = {
  borderColor: colors.primaryBeige,
};

export const search = {
  activeColor: colors.primary,
  borderRadius: '0px',
  fontWeight: 'normal',
  textTransform: 'normal',
};

export const shortBanner = {
  backgroundColor: colors.yellowDark,
  color: colors.text,
  titleFontWeight: 'medium',
  titleTextTransform: 'none',
};

export const table = {
  headerBackgroundColor: colors.primary,
  headerColor: colors.white,
  headerFontSize: '12px',
  headerLetterSpacing: '0px',
  scrollbarColor: colors.white,
};

export const upComingEvents = {
  backgroundColor: colors.primary,
  color: colors.white,
  titleFontWeight: 'light',
};

export default {
  adviceCardModule,
  articleDetail,
  articleListing,
  buttons,
  cardCtas,
  cards,
  carousel,
  categoryCards,
  categorySection,
  colors,
  columns,
  contactForm,
  contacts,
  cookieBanner,
  ctaBanner,
  ctaGroup,
  customBlockCards,
  documentDownload,
  fontSizes,
  fonts,
  fontWeights,
  footer,
  formInputs,
  header,
  headerBottom,
  heroBannerButton,
  heroSection,
  imageAndText,
  infoBlock,
  infoSection,
  ingredients,
  lineHeights,
  logo: Logo,
  media,
  mobileNavigation,
  name: 'Nuverta',
  navItems,
  newsListing,
  ownerDetail,
  product,
  productListing,
  radii,
  salesPerson,
  search,
  shadows,
  shortBanner,
  sizes,
  styles,
  table,
  text,
  typeStyles,
  upComingEvents,
};
