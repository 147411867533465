import { useConfiguration } from '@arvesta-websites/configuration';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Box, BoxProps } from 'rebass/styled-components';

import Button from '../Button';
import Cta from '../Cta';
import { withErrorBoundary } from '../ErrorBoundary';

import { StyledImage, StyledTextBox, StyledTextBoxDescription, StyledTextBoxTitle, StyledWrapper } from './Styled';

type CtaProps = {
  to: string;
  label: string;
};

export interface ShortBannerProps extends BoxProps {
  title?: string;
  shortDescription?: string;
  cta?: CtaProps | null;
  image?: {
    title: string;
    alt?: string;
    asset: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  orientation?: 'left' | 'right';
}

const ShortBanner = ({ title, shortDescription, cta, orientation = 'right', image }: ShortBannerProps) => {
  const config = useConfiguration();

  return (
    <StyledWrapper>
      <StyledImage>
        {image?.asset && (
          <GatsbyImage
            loading="eager"
            image={image.asset.gatsbyImageData}
            alt={image.alt ? image.alt : image.title}
            style={{ height: '100%', width: '100%' }}
          />
        )}
      </StyledImage>
      <StyledTextBox className={orientation}>
        {title && <StyledTextBoxTitle>{title}</StyledTextBoxTitle>}
        {shortDescription && <StyledTextBoxDescription>{shortDescription}</StyledTextBoxDescription>}
        {cta && (
          <div>
            <Cta to={cta.to} title={cta.label}>
              <Button variant={config.button?.shortBanner ?? 'primary'}>{cta.label}</Button>
            </Cta>
          </div>
        )}
      </StyledTextBox>
    </StyledWrapper>
  );
};

export default withErrorBoundary(ShortBanner, { componentName: 'ShortBanner' });
