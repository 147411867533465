import { ChevronRightEvent } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.categorySection.color};
  font-weight: ${({ theme }) => theme.categorySection.title?.fontWeight ?? 500};

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) =>
    Media({
      fontSize: [
        theme.categorySection.title?.fontSize?.mobile ?? '1.313rem',
        null,
        theme.categorySection.title?.fontSize?.tablet ?? '1.313rem',
        theme.categorySection.title?.fontSize?.desktop ?? '1.75rem',
      ],
      lineHeight: [
        theme.categorySection.title?.lineHeight?.mobile ?? '1.688rem',
        null,
        theme.categorySection.title?.lineHeight?.tablet ?? '1.688rem',
        theme.categorySection.title?.lineHeight?.desktop ?? '2.25rem',
      ],
    })}
`;

interface TruncatedComponentProps {
  lines?: string | string[];
}
export const CardTitle = styled(Title)<TruncatedComponentProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props?.lines ?? '2'};
`;

export const CardIcon = styled.img`
  ${Media({
    height: ['42px', null, '24px', '40px'],
    width: ['42px', null, '24px', '40px'],
  })}
`;

export const StyledChevron = styled(ChevronRightEvent)`
  position: absolute;
  z-index: 1;
  align-self: flex-end;
  opacity: ${({ theme }) => theme.categorySection.chevron.opacity || 0.9};

  & .iconCircle {
    fill: ${({ theme }) => theme.categorySection.chevron.fill};
  }

  & .iconArrow {
    stroke: ${({ theme }) => theme.categorySection.chevron.stroke};
  }
  &:hover {
    opacity: 1;
    & .iconCircle {
      fill: ${({ theme }) => theme.categorySection.chevron.hoverFill};
    }
    & .iconArrow {
      stroke: ${({ theme }) => theme.categorySection.chevron.hoverStroke};
    }
  }

  ${Media({
    bottom: [null, null, '12px', '24px'],
    display: ['none', null, 'block'],
    height: [null, null, null, '32px'],
    right: [null, null, '12px', '24px'],
    width: [null, null, null, '32px'],
  })}
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
  ${({ theme }) =>
    theme.categorySection.title?.wrapper?.maxWidth && `max-width: ${theme.categorySection.title.wrapper.maxWidth}`};

  &:hover {
    text-decoration: none;
  }

  ${Media({
    flexDirection: ['column', null, 'row'],
  })}
`;

export const CardContent = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;

  ${props =>
    Media({
      alignItems: ['center', null, props.theme.categorySection?.alignment ?? 'flex-start'],
      justifyContent: ['center', null, 'flex-start'],
      padding: ['1rem', null, '0.75rem', '1.5rem'],
      background: [
        props.theme.categorySection.backgroundImage
          ? `url(${props.theme.categorySection.backgroundImage})`
          : props.theme.categorySection.background,
        null,
        'none',
      ],
    })};

  background-size: cover;

  &:hover {
    text-decoration: none;
  }

  ${({ size }) => {
    if (size === 'large')
      return Media({
        minHeight: ['100px', null, '160px', '320px'],
      });

    return Media({
      minHeight: ['100px', null, '100px', '200px'],
    });
  }};
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  transition: all 0.3s ease-in-out;

  > div {
    width: 100% !important;
  }

  ${Media({
    display: ['none', null, 'flex'],
  })}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(0deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;

  ${Media({
    display: ['none', null, 'flex'],
  })}
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.categorySection.boxShadow};
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: none;

    ${Gradient} {
      height: 100%;
    }

    ${StyledImage} {
      transform: scale(1.05);
    }

    ${StyledChevron} {
      color: white;
      opacity: 1;
    }
  }

  ${Media({
    height: ['104px', null, '100%'],
  })}
`;
