import { FooterNuverta, Leaf, NewsLetterHeader } from '@arvesta-websites/icons';

import { Config } from '../default-config';
import mergeConfig from '../merge-config';

const config: Config = mergeConfig({
  CtaMask: Leaf,
  NewsletterHeader: {
    SVG: NewsLetterHeader.AveveAgrarisch,
    className: 'newsletter-svg',
  },
  breadcrumb: {
    display: false,
  },
  button: {
    ctaBanner: {
      image: 'secondary',
      text: 'primary-with-background',
    },
    dealerOverview: {
      details: 'primary',
      navigation: 'primary',
    },
    infoSection: 'primary-with-background',
    infoBlock: 'secondary',
    infoBlockColumns: 'secondary',
    shortBanner: 'primary-with-background',
  },
  footer: {
    newsletter: {
      removeBottomPadding: false,
    },
    version: 'V2',
  },
  heroBanner: {
    mask: false,
    customOverlay: true,
  },
  mapSearchRadius: 20000, // in meters!!
});

export default config;
