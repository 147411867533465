export default {
  'articleListing.applyFilters': 'Apply filters',
  'articleListing.filters': 'Filters',
  'articleListing.filtersClear': 'Clear filters',
  'articleListing.loadMore': 'Load more',
  'brandListing.all': 'All',
  'brandListing.byCategories': 'By categories',
  'brandListing.clear': 'Clear',
  'brandListing.productsAndServices': 'Products & Services',
  'brandListing.seeProductsFor': 'See products for',
  'brandListing.similarBrands': 'Brands with similar products & services',
  'brandListing.visitWebsite': 'Visit Website',
  'cards.advice.link': 'Read article',
  'cards.category.link': 'Read more',
  'cards.event.link': 'View event',
  'cards.news.link': 'Carry On Reading',
  'cards.product.link': 'View more',
  'footer.copyright': '© {year} Arvesta. All rights reserved.',
  'footer.follow_us': 'Follow us',
  'footer.newsletter.error': 'There was an error with the information you entered please try again.',
  'footer.newsletter.heading':
    'Would you like to receive news and advice that matches what interests you? We are happy to do it for you!',
  'footer.newsletter.placeholder': 'Enter email address',
  'footer.newsletter.subscribe': 'Subscribe',
  'footer.newsletter.success': 'You are all subscribed, thank you. You will hear from us shortly.',
  'footer.subscribe.belgium': 'Belgium',
  'footer.subscribe.enteremail': 'Enter email address',
  'footer.subscribe.failure': 'There was an error with the information you entered please try again.',
  'footer.subscribe.invalid_email': 'Invalid E-mail',
  'footer.subscribe.netherlands': 'Netherlands',
  'footer.subscribe.subscribe': 'Subscribe',
  'footer.subscribe.subscribing': 'Subscribing',
  'footer.subscribe.success': 'You are all subscribed, thank you. You will hear from us shortly.',
  'forms.contact.description': 'Fill out the form below and our team will be in touch.',
  'forms.contact.email.label': 'Email Address',
  'forms.contact.email.placeholder': 'Enter your email address',
  'forms.contact.errors.email': 'Invalid email',
  'forms.contact.errors.required': 'Field is required',
  'forms.contact.failure': 'There was an error with the information you entered please try again. ',
  'forms.contact.errors.telephone': `Please enter only numbers, spaces and the '+' symbol. The first character must be a '+' or a number`,
  'forms.contact.fullname.label': 'Full Name',
  'forms.contact.fullname.placeholder': 'Please enter your full name',
  'forms.contact.message.label': 'Message',
  'forms.contact.message.placeholder': 'Enter your message',
  'forms.contact.telephone.label': 'Telephone number',
  'forms.contact.telephone.placeholder': 'Fill in your telephone number',
  'forms.contact.other': 'Other',
  'forms.contact.newsletter.title':
    'Would you like to recieve emails with news, product updates, event information. You can subscribe at a later date.',
  'forms.contact.submit': 'Send',
  'forms.contact.success': "Thank you, we're already looking into your message",
  'forms.contact.title': 'Send us a message',
  'globals.download': 'Download',
  'globals.featuredProducts': 'Featured Products',
  'globals.no': 'No',
  'globals.yes': 'Yes',
  'header.search.placeholder': 'Search',
  'header.selectlanguage': 'Select Language',
  'header.allAbout': 'All about',
  'legacy.cookie.description': "By continuing to browse the site, you're agreeing to our use of",
  'legacy.footer.headoffice': 'Head office',
  'legacy.footer.headquarters': 'Headquarters',
  'legacy.header.about': 'about',
  'legacy.header.contact': 'contact',
  'legacy.header.jobs': 'jobs',
  'legacy.header.news': 'news & press',
  'legacy.header.products': 'products & services',
  'newscard.readmore': 'Read more',
  'newsletter.email.error': 'Please enter a valid email address',
  'newsletter.email.success': 'You successfully subscribed. You will hear from us shortly.',
  'newsletter.input.placeholder': 'E-mailadress',
  'newsletter.terms.error.sectionOne': 'Please agree to the',
  'newsletter.terms.error.sectionTwo': 'general privacy conditions',
  'newsletter.terms.privacyPolicy': 'privacy policy',
  'newsletter.terms.sectionOne': 'I acknowledge that I have received and read the',
  'newsletter.terms.sectionTwo': '.',
  'newsletter.terms.subscribe': 'Subscribe',
  'newsletter.terms.subscribe.error':
    'Unfortunately, something went wrong during your subscription. Please try again later.',
  'owner.storecard.contact': 'Contact us',
  'owner.storecard.route': 'Route',
  'owner.storecard.openinghours': 'Opening Hours',
  'owner.storecard.services': 'Services',
  'product.certifications.title': 'Product Certifications',
  'sections.advice.loadmore': 'Load more advice',
  'sections.category.loadmore': 'Load more categories',
  'sections.contact.offices': 'Offices',
  'sections.contact.openinghours': 'Opening Hours',
  'sections.contact.salesteam': 'Team',
  'sections.contact.viewonmap': 'View On Map',
  'sections.dealer.clear': 'CLEAR',
  'sections.dealer.invalidaddress': "Couldn't find address",
  'sections.dealer.loadmore': 'See all dealers',
  'sections.dealer.maplink': 'View on Map',
  'sections.dealer.placeholder': 'Enter your postcode and city',
  'sections.dealer.search': 'Search',
  'sections.dealer.total': '{total} Results for "{query}"',
  'sections.dealerOverview.details': 'Details',
  'sections.dealerOverview.route': 'Navigation',
  'sections.dealerOverview.no_results': 'No results',
  'sections.dealerOverview.show_all': 'Back to overview of all dealers',
  'sections.dealerOverview.clear_filters': 'Clear filters',
  'sections.dealerOverview.remove_filter': 'Remove filter',
  'sections.dealerOverview.services': 'Services',
  'sections.events.loadmore': 'Load more events',
  'sections.events.loadmorepast': 'Load more past events',
  'sections.news.loadmore': 'Load more news',
  'sections.product.loadmore': 'Load more',
  'sections.product.noproductsfoundfor': 'No Products found for',
  'sections.product.table.brochure': 'BROCHURE',
  'sections.product.table.download': 'Download',
  'sections.product.table.number': 'ART.NR',
  'sections.product.table.product': 'PRODUCT',
  'sections.product.table.weight': 'WEIGHT',
  'sections.search.events': 'Events',
  'sections.search.news': 'News',
  'sections.search.noncategorised': 'Non - categorised',
  'sections.search.noresults.title': 'Sorry we havent found any results for ',
  'sections.search.products': 'Products',
  'sections.search.results.title': '{total} Results for "{query}"',
  'sections.search.seeallevents': 'See all Events',
  'sections.search.seeallnews': 'See all News',
  'sections.search.seeallproducts': 'See all Products',
};
