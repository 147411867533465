import { useConfiguration } from '@arvesta-websites/configuration';
import { IntlContext } from '@arvesta-websites/i18n';
import { ByArvesta } from '@arvesta-websites/icons';
import { useContext, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { tv } from 'tailwind-variants';

// eslint-disable-next-line
import { LocaleType } from 'libs/ui-babel/src/types';

import {
  NewsLetter,
  type NewsLetterProps,
  NewsletterContainer,
  createNewsletterFormSubmitHandler,
  newsletterInitialState,
  newsletterReducer,
} from '../../../modules';
import Cta from '../../Cta';
import { withErrorBoundary } from '../../ErrorBoundary';
import Logo from '../../Logo';
import RichText from '../../RichText';
import { MainNavigationDefault } from '../MainNavigation';

import { StyledRichTextWrapper, StyledSocialIcons } from './Styled';

type Link = {
  to: string;
  label: string;
};

type Navigation = {
  title: string;
  children: Array<Link>;
};

type RichTextSection = {
  __typename: 'ContentfulRichTextSection';
  richText: {
    json: any;
  };
};

const brandTypes = {
  ABrandOfArvesta: 'A brand of Arvesta',
  brandByArvesta: 'Brand by Arvesta',
} as const;

type BrandTypes = (typeof brandTypes)[keyof typeof brandTypes];

type FooterDefaultProps = {
  legalLinks: Array<Link>;
  socialLinks: Array<Link>;
  banner?: any;
  siteLogo?: any;
  pageLocale: string;
  newsletter: Omit<NewsLetterProps, 'submitHandler' | 'state' | 'setChecked' | 'setEmail'>;
  handleNewsletterFormSubmission: (
    email: string,
    country: string,
    locale: string,
    campaignName: string,
  ) => Promise<number>;
  navigation: {
    firstColumn: Navigation;
    secondColumn: Navigation;
    thirdColumn: Navigation | RichTextSection;
    fourthColumn: Navigation | RichTextSection;
  };
  brandType: BrandTypes;
  siteName: string;
  defaultLocale: LocaleType;
};

const styling = tv({
  slots: {
    bgImage:
      'absolute bottom-0 left-1/2 -translate-x-1/2 !z-0 fill-white w-[90%] lg:w-[470px] lg:left-auto lg:right-12 lg:transform-none',
    byArvesta: 'h-[28px]',
    cta: 'text-footer no-underline legal-links hover:underline hover:decoration-solid hover:decoration-1 hover:underline-offset-1 before:block before:h-0 before:content-[attr(title)] before:overflow-hidden before:font-normal',
    footer: 'relative bg-footer-background text-footer -mt-[1px]',
    footerContent:
      'relative grid footer-content py-[1.875rem] *:z-10 lg:py-10 xl:py-[1.875rem] xl:my-0 xl:mx-auto xl:max-w-screen-xl',
    fourthColumnWrapper: 'flex flex-col gap-[2rem] footer-fourth-column',
    label: 'text-sm font-bold socials-label',
    legalWrapper:
      'flex gap-2.5 justify-center flex-wrap py-3.5 px-[1.125rem] lg:gap-5 lg:py-6 lg:px-8 xl:gap-10 xl:py-5 xl:px-12 footer-legal-wrapper',
    logoBanner: 'footer-logo-banner flex items-center justify-center gap-1 px-[1.125rem] lg:px-8 xl:px-12',
    socialsWrapper: 'flex flex-col gap-5 z-10',
    svgDesktop: 'fill-primary hidden lg:block',
    svgMobile: 'fill-primary block md:hidden',
    svgTablet: 'fill-primary hidden md:block lg:hidden',
    wrapper: 'w-full my-0 mx-auto',
  },
  variants: {
    withNewsletter: {
      true: {
        svgDesktop: 'bg-footer-secondary',
        svgMobile: 'bg-footer-secondary',
        svgTablet: 'bg-footer-secondary',
      },
    },
  },
});

const FooterDefault = ({
  legalLinks,
  navigation,
  socialLinks,
  banner,
  siteLogo,
  pageLocale,
  newsletter,
  handleNewsletterFormSubmission,
  brandType,
  siteName,
  defaultLocale,
}: FooterDefaultProps) => {
  const intl = useIntl();
  const locale: any = useContext(IntlContext);
  const config = useConfiguration();
  const FooterSVG = config.footer?.svg;

  const {
    bgImage,
    cta,
    footer,
    footerContent,
    label,
    legalWrapper,
    socialsWrapper,
    wrapper,
    svgDesktop,
    svgMobile,
    svgTablet,
    fourthColumnWrapper,
  } = styling();

  return (
    <>
      {newsletter && (
        <NewsletterContainer {...newsletter} removeBottomPadding={!!config.footer?.newsletter?.removeBottomPadding} />
      )}

      {FooterSVG && (
        <>
          <FooterSVG.Desktop className={svgDesktop({ withNewsletter: !!newsletter })} />
          <FooterSVG.Tablet className={svgTablet({ withNewsletter: !!newsletter })} />
          <FooterSVG.Mobile className={svgMobile({ withNewsletter: !!newsletter })} />
        </>
      )}

      <div className={footer()}>
        <div className={wrapper()}>
          <div className={footerContent()}>
            <Column column={navigation.firstColumn} />
            <Column column={navigation.secondColumn} />
            <Column column={navigation.thirdColumn} />

            <div className={fourthColumnWrapper()}>
              <Column column={navigation.fourthColumn} />
              {socialLinks && (
                <div className={socialsWrapper()}>
                  <span className={label()}>{intl.formatMessage({ id: 'footer.follow_us' })}</span>
                  <StyledSocialIcons socialLinks={socialLinks} />
                </div>
              )}
            </div>

            <img src={banner?.file?.url} alt="" className={bgImage()} />
          </div>

          <LogoBanner
            siteLogo={siteLogo}
            pageLocale={pageLocale}
            brandType={brandType}
            defaultLocale={defaultLocale}
            siteName={siteName}
          />

          <div className={legalWrapper()}>
            {legalLinks?.map((item, key) => (
              <Cta key={`${item.to}-${key}`} to={item.to} className={cta()} title={item.label}>
                {item.label}
              </Cta>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const isRichText = (item: RichTextSection | Navigation): item is RichTextSection => {
  return (item as RichTextSection).richText !== undefined;
};

type ColumnProps = {
  column: Navigation | RichTextSection;
};

const Column = ({ column }: ColumnProps) => {
  if (!column) {
    return <div aria-hidden="true" />;
  }

  if (isRichText(column)) {
    return (
      <StyledRichTextWrapper>
        <RichText data={column.richText.json} isFooter />
      </StyledRichTextWrapper>
    );
  }

  return <MainNavigationDefault navigation={column.children} title={column.title} />;
};

type LogoBannerProps = {
  siteLogo: any;
  pageLocale: string;
  brandType: BrandTypes;
  siteName: string;
  defaultLocale: LocaleType;
};

const LogoBanner = ({ pageLocale, siteName, defaultLocale }: LogoBannerProps) => {
  const { logoBanner, byArvesta } = styling();
  const { footer: footerConfig } = useConfiguration();

  return (
    <div className={logoBanner()}>
      {footerConfig.byArvesta ? (
        <a href={`https://www.arvesta.eu/${pageLocale}`} rel="noreferrer" target="_blank">
          <ByArvesta className={byArvesta()} />
        </a>
      ) : (
        <Logo siteName={siteName} defaultLocale={defaultLocale} height="54px" />
      )}
    </div>
  );
};

export default withErrorBoundary(FooterDefault, { componentName: 'FooterDefault' });
