import { Radio, RadioChecked, RadioNested } from '@arvesta-websites/icons';
import React, { useState } from 'react';
import { tv } from 'tailwind-variants';

import { withErrorBoundary } from '../../components';

import type { Article, Category } from './ArticleListingModule';
import { StyledFilterItem, StyledPageExtra, StyledRadioWrapper } from './Styled';

type FilterIconProps = {
  activeCategoriesIdParent: string | null;
  itemId: string;
  isChildActive: boolean;
};

type FiltersProps = {
  categories: Category[];
  activeCategoriesId: { child: string | null; parent: string | null };
  handleMenuClick: (item: Category) => void;
  articles: Article[];
};

type SubFiltersProps = {
  category: Category;
  activeCategoriesId: { child: string | null; parent: string | null };
  handleClick: (item: Category) => void;
  articles: Article[];
};

const categoryStyle = tv({
  base: 'font-normal',
  variants: {
    active: {
      true: 'font-bold',
      false: 'font-normal',
    },
  },
});

const getLastItem = (category: Category, index: number) => {
  if (!category.children) {
    return false;
  }

  return index === category.children.length - 1;
};

const FilterIcon = ({ activeCategoriesIdParent, itemId, isChildActive }: FilterIconProps) => {
  if (activeCategoriesIdParent === itemId && !isChildActive) {
    return <RadioChecked />;
  } else if (activeCategoriesIdParent === itemId && isChildActive) {
    return <RadioNested />;
  } else {
    return <Radio />;
  }
};

const calculateArticleCount = (category: Category, articles: Article[], parentId?: string) => {
  if (parentId) {
    return articles.filter(article =>
      article.categories?.some(
        cat => cat.id === category.id && article.categories.some(parentCat => parentCat.id === parentId),
      ),
    ).length;
  }

  return articles.filter(article => article.categories?.some(cat => cat.id === category.id)).length;
};

const SubFilters = ({
  category,
  activeCategoriesId,
  handleClick,
  articles,
}: SubFiltersProps & { articles: Article[] }) => {
  if (activeCategoriesId.parent !== category.id) {
    return null;
  }

  if (!category.children) {
    return null;
  }

  return category.children.map((child, index) => {
    const actualCount = calculateArticleCount(child, articles, category.id);

    if (actualCount === 0) {
      return null;
    }

    return (
      <StyledFilterItem lastItem={getLastItem(category, index)} key={child.id}>
        <li>
          <StyledRadioWrapper
            nested
            active={activeCategoriesId.child === child.id}
            className={categoryStyle({
              active: activeCategoriesId.child === child.id,
            })}
            onClick={e => {
              e.stopPropagation();
              handleClick(child);
            }}
          >
            {activeCategoriesId.child === child.id ? <RadioChecked /> : <Radio />}
            <span>{child.label}</span>
            <StyledPageExtra>({actualCount})</StyledPageExtra>
          </StyledRadioWrapper>
        </li>
      </StyledFilterItem>
    );
  });
};

const Filters = ({ categories, activeCategoriesId, handleMenuClick, articles }: FiltersProps) => {
  const [childActive, setChildActive] = useState(false);

  if (!categories) {
    return null;
  }

  return categories?.map(category => {
    const actualCount = calculateArticleCount(category, articles);

    if (actualCount === 0) {
      return null;
    }

    return (
      <StyledFilterItem key={category.id}>
        <li>
          <StyledRadioWrapper
            active={
              activeCategoriesId.parent === category.id || (!activeCategoriesId.child && !activeCategoriesId.parent)
            }
            onClick={() => {
              handleMenuClick(category);
              setChildActive(false);
            }}
            className={categoryStyle({
              active: activeCategoriesId.parent === category.id || activeCategoriesId.child === category.id,
            })}
          >
            <FilterIcon
              activeCategoriesIdParent={activeCategoriesId.parent}
              itemId={category.id}
              isChildActive={childActive}
            />
            <span>{category.label}</span>
            <StyledPageExtra>({actualCount})</StyledPageExtra>
          </StyledRadioWrapper>
          <SubFilters
            category={category}
            activeCategoriesId={activeCategoriesId}
            handleClick={item => {
              handleMenuClick(item);
              setChildActive(true);
            }}
            articles={articles}
          />
        </li>
      </StyledFilterItem>
    );
  });
};

export default withErrorBoundary(Filters, { componentName: 'ArticleListingModule/Filters' });
