import { IntlContext } from '@arvesta-websites/i18n';
import React, { useContext, useReducer } from 'react';

import { handleNewsletterFormSubmission } from '../../../utils';

import NewsLetter from './NewsLetter';
import { createNewsletterFormSubmitHandler, newsletterInitialState, newsletterReducer } from './utils';

type NewsletterContainerProps = {
  title?: string;
  description?: string;
  privacyPolicyUrl?: string;
  campaignName?: string;
  removeBottomPadding: boolean;
  submitHandler?: (e: React.SyntheticEvent) => void;
  state?: {
    email: string;
    checked: boolean;
    termsError: boolean;
    emailError: boolean;
    success: boolean;
    submitting: boolean;
    submitError: boolean;
  };
  setChecked?: (value: boolean) => void;
  setEmail?: (value: string) => void;
};

const NewsletterContainer = (props: NewsletterContainerProps): JSX.Element => {
  const locale = useContext(IntlContext);

  const { submitHandler, state, setChecked, setEmail, ...newsletter } = props;

  const [newsletterState, dispatch] = useReducer(newsletterReducer, newsletterInitialState);
  const onSubscribeFormSubmit = createNewsletterFormSubmitHandler(
    newsletterState,
    dispatch,
    handleNewsletterFormSubmission,
    locale,
    newsletter,
  );

  return (
    <NewsLetter
      state={newsletterState}
      setChecked={(value: boolean) => {
        dispatch({ payload: value, type: 'SET_CHECKED' });
        dispatch({ payload: false, type: 'SET_TERMS_ERROR' });
      }}
      setEmail={(value: string) => {
        dispatch({ payload: value, type: 'SET_EMAIL' });
        dispatch({ payload: false, type: 'SET_EMAIL_ERROR' });
        dispatch({ payload: false, type: 'SET_SUBMIT_ERROR' });
      }}
      submitHandler={onSubscribeFormSubmit}
      {...newsletter}
    />
  );
};

export default NewsletterContainer;
