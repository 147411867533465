import { LocaleType } from '@arvesta-websites/i18n';
import { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

import { NavItemType } from '../../../../../types';
import Utils from '../../../../../utils';
import { withErrorBoundary } from '../../../ErrorBoundary';

type PrimaryNavigationDefaultProps = {
  items: Array<NavItemType>;
  currentItem: NavItemType | null;
  activeMenuItem: NavItemType | null;
  handleClickMenuItem: (item: NavItemType) => void;
  variant?: string;
  defaultLocale: LocaleType;
};

const primaryNavigation = tv({
  compoundVariants: [
    {
      class: {
        navigation: 'justify-start min-[920px]:w-full ',
      },
      variant: 'secondary',
    },
  ],
  slots: {
    listItem: 'list-none inline-block text-center font-normal',
    navItem:
      'relative whitespace-nowrap navItem primaryNavigation py-[2rem] px-0 no-underline text-content inline-block align-center cursor-pointer tracking-normal leading-7 hover:font-black hover:underline hover:underline-offset-base',
    navigation: 'list-none m-0 p-0 hidden gap-[20px] lg:flex justify-around w-auto navigation',
  },
});

const PrimaryNavigationDefault = ({
  items,
  activeMenuItem,
  currentItem,
  handleClickMenuItem,
}: PrimaryNavigationDefaultProps) => {
  const [clicked, setClicked] = useState(false);

  const closeTimer = useRef<number | null>(null);

  const { navigation, listItem, navItem } = primaryNavigation();

  useEffect(() => {
    setClicked(false);
  }, [currentItem]);

  return (
    <ul className={navigation()}>
      {items.map((item: NavItemType, idx: number) => {
        return (
          <li
            key={`primary-active-${item.to}-${idx}`}
            onFocus={() => handleClickMenuItem(item)}
            className={listItem({ bold: activeMenuItem?.to === item.to })}
          >
            <a
              href={item?.children?.length ? undefined : Utils.getIntlSlug(item.externalUrl || item.to)}
              title={item.label}
              onMouseEnter={e => {
                closeTimer.current = window.setTimeout(() => {
                  if (!clicked && currentItem?.to === item.to) {
                    e.preventDefault();
                    setClicked(true);
                  }
                  handleClickMenuItem(item);
                }, 300);
              }}
              onMouseLeave={() => {
                if (closeTimer.current) {
                  window.clearTimeout(closeTimer.current);
                  closeTimer.current = null;
                }
              }}
              className={navItem()}
              target={item.externalUrl ? '_blank' : undefined}
              rel="noreferrer"
            >
              {item.label}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default withErrorBoundary(PrimaryNavigationDefault, { componentName: 'PrimaryNavigationDefault' });
