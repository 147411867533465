import React from 'react';

const Logo = () => (
  <svg id="Laag_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 40">
    <defs>
      <style>{`.st0 { fill: #153225; }`}</style>
    </defs>
    <g>
      <path
        className="st0"
        d="M78.8,9.6c-1.4-.8-3-1.2-4.8-1.2-1.2,0-2.7.3-3.8.8-1.1.5-2,1-2.8,1.7-.6-1.3-1.9-2.5-3.4-2.5h-2v22.9h5.9v-12.5c0-1.4.4-2.6,1.3-3.5.9-.9,2-1.4,3.4-1.4s1.7.2,2.4.6c.7.4,1.3,1,1.6,1.7.4.7.6,1.6.6,2.6v9.4c0,1.9,1.5,3.4,3.4,3.4h2.5v-13.5c0-1.9-.4-3.6-1.1-5-.8-1.5-1.8-2.6-3.2-3.4"
      />
      <path
        className="st0"
        d="M89.4,30.4c1.4.8,3,1.2,4.8,1.2,1.2,0,2.7-.3,3.8-.8,1.1-.5,2-1,2.8-1.7.6,1.3,1.9,2.5,3.4,2.5h2V8.7h-5.9v12.5c0,1.4-.4,2.6-1.3,3.5-.9.9-2,1.4-3.4,1.4s-1.7-.2-2.4-.6c-.7-.4-1.3-1-1.6-1.7s-.6-1.6-.6-2.6v-9.4c0-1.9-1.5-3.4-3.4-3.4h-2.5v13.5c0,1.9.4,3.6,1.1,5,.8,1.5,1.8,2.6,3.2,3.4"
      />
    </g>
    <polygon className="st0" points="123.7 8.7 118.3 24.4 112.9 8.7 107 8.7 115.6 31.3 121 31.3 129.6 8.7 123.7 8.7" />
    <g>
      <path
        className="st0"
        d="M147.3,11.7c-1-1-2.1-1.8-3.4-2.4-1.3-.6-2.7-.8-4.2-.8s-3,.3-4.4.9c-1.4.6-2.6,1.4-3.6,2.5-1,1-1.9,2.3-2.5,3.7-.6,1.4-.9,2.9-.9,4.5s.3,3.1.9,4.5,1.4,2.6,2.5,3.7c1,1.1,2.2,1.9,3.6,2.5,1.4.6,2.8.9,4.4.9s2.5-.2,3.6-.6c1.1-.4,2.1-1,3-1.6.9-.7,1.6-1.4,2.2-2.3.6-.9,1.1-1.7,1.4-2.6h-5.9c-.4.5-.9.9-1.6,1.2-.7.3-1.6.5-2.6.5s-1.4,0-2-.3c-.6-.2-1.1-.5-1.5-.8-.4-.4-.8-.8-1.1-1.3-.3-.5-.5-1-.6-1.5h15.7c.2-.8.2-1.5.2-2.2,0-1.7-.3-3.3-.9-4.7-.6-1.4-1.4-2.6-2.3-3.6M134.3,18.3c.1-.6.3-1.2.6-1.7s.7-1,1.1-1.4c.5-.4,1-.7,1.6-.9.6-.2,1.3-.3,2-.3s1.4.1,2,.3c.6.2,1.1.5,1.6.9.5.4.8.9,1.1,1.4.3.5.5,1.1.6,1.7h-10.8Z"
      />
      <path
        className="st0"
        d="M197.5,11c-.8-.8-1.7-1.4-2.8-1.8-.9-.4-1.9-.6-3-.6h0s-8.6,0-8.6,0v1.7c0,1.9,1.5,3.4,3.4,3.4h4.1c1.1,0,2,.3,2.7,1,.7.7,1,1.7,1,2.9h-.1c-2.5,0-4.6.2-6.4.6-1.7.4-3.1.9-4.1,1.6-1,.7-1.8,1.5-2.2,2.4s-.7,2-.7,3.1.2,1.8.5,2.6c.3.8.8,1.5,1.5,2,.7.6,1.5,1,2.4,1.3,1,.3,2,.5,3.2.5s2.3-.2,3.4-.6c1.1-.4,2.1-1,3-1.8.6,1.3,1.9,2.5,3.4,2.5h1.8v-14.3c0-1.3-.2-2.5-.7-3.5-.4-1.1-1.1-2-1.8-2.8M194.3,22.5c0,.7-.1,1.3-.3,1.9-.2.6-.6,1-1,1.5-.4.4-1,.8-1.5,1-.6.2-1.3.3-2,.3s-1.6-.3-2.1-.8c-.6-.5-.8-1.1-.8-1.7s0-.9.3-1.3c.2-.4.6-.7,1.2-1,.6-.3,1.4-.5,2.4-.6,1-.1,2.3-.2,3.9-.2h.1v1Z"
      />
      <path
        className="st0"
        d="M179.7,8.5h-4.9V3.3h-4.9v3c0,.9-.2,1.5-.7,2,0,0-.2.2-.3.2h-5.7c-.9,0-1.6.1-2.3.4-.7.3-1.2.6-1.7.9-.6.4-1,1.1-1.4,1.6l-.2-.6c-.5-1.3-1.7-2.4-3.1-2.4h-1.9v22.9h5.7v-12.5c0-.8.1-1.7.3-2.3.2-.6.6-1.1,1-1.5.4-.4.9-.8,1.5-1,.6-.2,1.2-.3,1.9-.3h6.2v9.8c0,1.2.2,2.3.6,3.3.4,1,.9,1.9,1.6,2.6.7.7,1.5,1.3,2.4,1.6.9.4,2,.6,3.1.6s1.8-.2,2.7-.5v-4.9c-.5.2-1,.2-1.5.2-1,0-1.8-.3-2.3-.9-.6-.6-.9-1.4-.9-2.3v-9.6h3.3c1.7,0,3.4-1.5,3.4-3.4v-1.7h-1.8Z"
      />
      <path
        className="st0"
        d="M9.7,18L2.6,7.5C1.5,5.8.6,4.2.2,3.1-.3,1.9.1.9,1.9.5c0,0,.1,0,.2,0,.7-.2,1.6-.3,2.4-.4C5.4,0,6.1,0,6.6,0c.7,0,1.5,0,2.2.1s1.4.2,2.2.4c1.4.3,3.1.9,4.6,1.6,1.5.7,2.8,1.4,3.6,2.2,1.3.9,2.7,2.2,3.9,3.5,1.2,1.3,2.3,2.7,2.9,3.8l7.1,10.5,7,10.5c1.1,1.7,2,3.3,2.4,4.4.5,1.2,0,2.1-1.7,2.5,0,0-.2,0-.2,0-.7.2-1.6.3-2.4.4-.8,0-1.6.1-2,.1-.7,0-1.5,0-2.2-.1-.7,0-1.4-.2-2.2-.4-1.4-.3-3.1-.9-4.6-1.6-1.5-.7-2.8-1.4-3.7-2.2-1.3-.9-2.7-2.2-3.9-3.5-1.2-1.3-2.3-2.7-2.9-3.8l-7.1-10.5Z"
      />
      <path
        className="st0"
        d="M41.2,6.1c.3-2.1-1.3-3.9-3.4-3.9h-6.3c-3.5,0-4.5,2.7-2.2,6.1l5.5,8.3c2.3,3.4,4.6,2.9,5.1-1.2l1.3-9.3Z"
      />
      <path
        className="st0"
        d="M1.6,33.9c-.3,2.1,1.3,3.9,3.4,3.9h6.3c3.5,0,4.5-2.7,2.2-6.1l-5.5-8.3c-2.3-3.4-4.6-2.9-5.1,1.2l-1.3,9.3Z"
      />
    </g>
  </svg>
);

export default Logo;
