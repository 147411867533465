import styled from 'styled-components';

import Media from '../../../../utils/Media';
import Cta from '../../Cta';

export const StyledDescription = styled.div`
  color: ${props => props.theme.cards.article.description};
  padding-top: 9px;
  font-size: 16px;
  flex: 1;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledImageWrapper = styled.div`
  box-shadow: ${props => props.theme.articleCard?.boxShadow ?? '3px 3px 3px #00000029'};

  ${Media({
    height: ['214px', '214px', '214px', '200px'],
  })}
`;

export const StyledLink = styled.div`
  ${props => props.theme.cards.article.link};
  cursor: pointer;
  padding-top: 24px;
  opacity: 0.8;
  font-size: ${props => props.theme.cards.article.link?.fontSize ?? '14px'};
  font-weight: ${props => props.theme.cards.article.link?.fontWeight ?? 'bold'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTitle = styled.div`
  color: ${props => props.theme.cards.article.title.color};
  font-size: ${props => props.theme.cards.article.title?.fontSize ?? '21px'};
  font-weight: ${props => props.theme.cards.article.title?.fontWeight ?? '700'};
  padding-top: 24px;
  font-family: ${props => props.theme.fonts.heading} !important;
  line-height: ${props => props.theme.cards.article.title?.lineHeight};
  /* overflow: hidden; */
  /* max-height: 4em; */

  &:hover {
    text-decoration: none;
  }
`;

export const StyledWrapper = styled(Cta)`
  //background-color: white;
  display: flex !important;
  flex-direction: column;
  text-decoration: none;

  ${Media({
    width: ['100%', '100%', '340px', '318px'],
  })}
`;
