import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { withErrorBoundary } from '../../../../components';

import { StyledDescription, StyledImageWrapper, StyledLink, StyledTitle, StyledWrapper } from './Styled';

type Image = {
  title: string;
  alt?: string;
  asset: { gatsbyImageData: IGatsbyImageData };
};

export interface CarouselItemCardProps {
  key: string;
  title?: string;
  description?: string;
  image?: Image;
  ctaLabel?: string;
  link?: string;
}

const getImageData = (image?: Image): IGatsbyImageData | null => {
  if (image && image.asset) {
    return image.asset.gatsbyImageData;
  }

  console.warn('NewsCard: No image data provided', JSON.stringify(image));

  return null;
};

const CarouselItemCard = ({ title, description, image, ctaLabel, link, ...rest }: CarouselItemCardProps) => {
  return (
    <StyledWrapper to={link} {...rest}>
      <StyledImageWrapper>
        {image && getImageData(image) && (
          <GatsbyImage
            alt={image.alt ? image.alt : image.title}
            image={getImageData(image)}
            objectFit="cover"
            style={{ height: '100%', margin: '0 auto', width: '100%' }}
          />
        )}
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledWrapper>
  );
};

export default withErrorBoundary(CarouselItemCard, { componentName: 'CarouselItemCard' });
