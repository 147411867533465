import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';

import { withErrorBoundary } from '../ErrorBoundary';

import { StyledImageWrapper, StyledWrapper } from './Styled';

type NecessaryImageProps = Omit<GatsbyImageProps, 'image' | 'alt'>;

export type ImageProps = NecessaryImageProps & {
  altText: string;
  asset: {
    gatsbyImageData: IGatsbyImageData;
  };
  position: 'Left' | 'Center';
  size: 'Small' | 'Medium' | 'Large';
};

const Image = ({ altText, asset, position, size }: ImageProps) => {
  if (!asset?.gatsbyImageData) {
    return null;
  }

  return (
    <StyledWrapper position={position}>
      <StyledImageWrapper size={size}>
        <GatsbyImage
          image={asset.gatsbyImageData}
          alt={altText}
          objectFit="cover"
          style={{ height: '100%', margin: '0 auto' }}
        />
      </StyledImageWrapper>
    </StyledWrapper>
  );
};

export default withErrorBoundary(Image, { componentName: 'Image' });
