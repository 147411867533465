import { ChevronRightEvent } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.categorySection.color};
  font-weight: ${({ theme }) => theme.categorySection.title?.fontWeight ?? 500};
  line-height: ${({ theme }) => theme.categorySection.title?.lineHeight ?? '2.188rem'};

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) =>
    Media({
      fontSize: [
        theme.categorySection.title?.fontSize?.mobile ?? '1.313rem',
        null,
        theme.categorySection.title?.fontSize?.tablet ?? '1.313rem',
        theme.categorySection.title?.fontSize?.desktop ?? '1.875rem',
      ],
      maxWidth: [
        theme.categorySection.title?.maxWidth?.mobile ?? '90%',
        null,
        null,
        theme.categorySection.title?.maxWidth?.desktop ?? '75%',
      ],
    })}
`;

interface TruncatedComponentProps {
  lines?: string | string[];
}
export const CardTitle = styled(Title)<TruncatedComponentProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
  ${Media({
    WebkitLineClamp: ['2', null, null, '3', null],
  })}
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  transition: all 0.3s ease-in-out;

  ${Media({
    height: ['0 !important', null, '100% !important'],
    paddingBottom: ['100%', null, '0px'],
    position: [null, null, 'absolute !important'],
    width: ['100% !important', null, '100%'],
  })}
`;

export const StyledChevron = styled(ChevronRightEvent)`
  align-self: flex-end;
  opacity: ${({ theme }) => theme.categorySection.chevron.opacity || 1};
  flex-shrink: 0;

  & .iconCircle {
    fill: ${({ theme }) => theme.categorySection.chevron.fill};
  }

  & .iconArrow {
    stroke: ${({ theme }) => theme.categorySection.chevron.stroke};
  }
  &:hover {
    opacity: 1;
    & .iconCircle {
      fill: ${({ theme }) => theme.categorySection.chevron.hoverFill};
    }
    & .iconArrow {
      stroke: ${({ theme }) => theme.categorySection.chevron.hoverStroke};
    }
  }

  ${Media({
    height: [null, null, null, '32px'],
    width: [null, null, null, '32px'],
  })};
`;

export const TitleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
  }

  ${Media({
    padding: ['1.25rem', null, null, '1.875rem'],
  })}
`;

export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.categorySection.boxShadow};

  ${Media({
    minWidth: ['85%', null, '100%'],
    paddingBottom: [null, null, '100%'],
  })}

  &:hover {
    ${Gradient} {
      height: 100%;
    }

    ${StyledImage} {
      transform: scale(1.05);
    }
  }
`;

export const LocationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: block;

  ${Media({
    top: ['15px', null, null, '30px'],
  })}

  &:hover {
    text-decoration: none;
  }
`;

export const StyledDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.categorySection.date};
  color: ${({ theme }) => theme.categorySection.dateColor};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  text-align: center;

  ${Media({
    width: ['130px', null, '168px', '168px'],
    height: ['25px', null, '29px', '29px'],
    fontSize: ['16px', null, '20px', '20px'],
  })}
`;
export const StyledLocation = styled.div`
  display: block;
  font-weight: 300;
  color: ${({ theme }) => theme.categorySection.color};
  font-family: ${({ theme }) => theme.fonts.heading};
  text-transform: uppercase;
  padding-left: 30px;

  ${Media({
    fontSize: ['16px', null, '20px', '20px'],
    paddingLeft: ['20px', null, '30px'],
    paddingTop: ['10px', null, '8px'],
  })}
`;
