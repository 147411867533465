import * as React from 'react';
import { useContext } from 'react';

import alliance from './alliancegroup/config';
import arieblok from './arieblok/config';
import arvesta from './arvesta/config';
import aveveagrarisch from './aveveagrarisch/config';
import avevebiochem from './avevebiochem/config';
import avevemedia from './avevemedia/config';
import belcrop from './belcrop/config';
import cofabel from './cofabel/config';
import defaultConfig, { type Config } from './default-config';
import equifirst from './equifirst/config';
import equilannoo from './equilannoo/config';
import eurotuin from './eurotuin/config';
import hafner from './hafner/config';
import hermoo from './hermoo/config';
import hobbyfirst from './hobbyfirst/config';
import hortiplan from './hortiplan/config';
import kasperfaunafood from './kasperfaunafood/config';
import lannoo from './lannoo/config';
import naturalgranen from './naturalgranen/config';
import nuverta from './nuverta/config';
import osmo from './osmo/config';
import palital from './palital/config';
import peka from './peka/config';
import pentatrading from './pentatrading/config';
import pomagro from './pomagro/config';
import proxani from './proxani/config';
import sabe from './sabe/config';
import sanac from './sanac/config';
import scoriethom from './scoriethom/config';
import servagri from './servagri/config';
import spoormans from './spoormans/config';
import walagri from './walagri/config';
import wimlybaert from './wimlybaert/config';

const ConfigContext = React.createContext<Config>(defaultConfig);
const config = {
  alliance,
  arieblok,
  arvesta,
  aveveagrarisch,
  avevebiochem,
  avevemedia,
  belcrop,
  cofabel,
  equifirst,
  equilannoo,
  eurotuin,
  hafner,
  hermoo,
  hobbyfirst,
  hortiplan,
  kasperfaunafood,
  lannoo,
  naturalgranen,
  nuverta,
  osmo,
  palital,
  peka,
  pentatrading,
  pomagro,
  proxani,
  sabe,
  sanac,
  scoriethom,
  servagri,
  spoormans,
  walagri,
  wimlybaert,
};

const useConfiguration = () => {
  const config = useContext(ConfigContext);
  return config;
};

export { ConfigContext, config, useConfiguration, Config };
