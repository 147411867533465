import { ChevronRightEvent } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

interface StyledTitleProps {
  inColumn?: boolean;
}

// note: this is a duplicate for the styling in TopCard styling so reduntant
export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.categorySection.color};
  font-weight: ${({ theme }) => theme.categorySection.title?.fontWeight ?? 500};
  line-height: ${({ theme }) => theme.categorySection.title?.lineHeight ?? '2.188rem'};

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) =>
    Media({
      fontSize: [
        theme.categorySection.title?.fontSize?.mobile ?? '1.313rem',
        null,
        theme.categorySection.title?.fontSize?.tablet ?? '1.313rem',
        theme.categorySection.title?.fontSize?.desktop ?? '1.875rem',
      ],
      maxWidth: [
        theme.categorySection.title?.maxWidth?.mobile ?? '90%',
        null,
        null,
        theme.categorySection.title?.maxWidth?.desktop ?? '75%',
      ],
    })}
`;

export const CardTitle = styled(Title)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
  ${Media({
    WebkitLineClamp: ['1', null, null, '2', null],
  })}
`;

export const StyledChevron = styled(ChevronRightEvent)`
  align-self: flex-end;
  opacity: ${({ theme }) => theme.categorySection.chevron.opacity || 1};
  flex-shrink: 0;

  & .iconCircle {
    fill: ${({ theme }) => theme.categorySection.chevron.fill};
  }

  & .iconArrow {
    stroke: ${({ theme }) => theme.categorySection.chevron.stroke};
  }
  &:hover {
    opacity: 1;
    & .iconCircle {
      fill: ${({ theme }) => theme.categorySection.chevron.hoverFill};
    }
    & .iconArrow {
      stroke: ${({ theme }) => theme.categorySection.chevron.hoverStroke};
    }
  }

  ${Media({
    height: [null, null, null, '32px'],
    width: [null, null, null, '32px'],
  })}
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
`;

export const TitleWrapper = styled(Box)<StyledTitleProps>`
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${props =>
    Media({
      minHeight: props.inColumn ? ['100px', '200px', '200px', '200px'] : ['100px', null, null, '200px'],
      padding: ['1.25rem', null, null, '1.875rem'],
    })}
`;

export const Gradient = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.categorySection.boxShadow};

  &:hover {
    text-decoration: none;

    ${Gradient} {
      height: 100%;
    }

    ${StyledImage} {
      transform: scale(1.05);
    }
  }
`;

export const LocationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;

  ${Media({
    top: ['15px', null, null, '30px'],
  })}

  &:hover {
    text-decoration: none;
  }
`;

export const StyledDate = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.categorySection.date};
  color: ${({ theme }) => theme.categorySection.dateColor};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  text-align: center;
  flex-grow: 0;

  ${Media({
    width: ['130px', null, null, '168px'],
    height: ['25px', null, '29px', '29px'],
    fontSize: ['16px', null, '20px', '20px'],
  })}
`;
export const StyledLocation = styled(Box)`
  display: flex;
  align-items: center;
  font-weight: 300;
  color: ${({ theme }) => theme.categorySection.color};
  font-family: ${({ theme }) => theme.fonts.heading};
  text-transform: uppercase;

  ${Media({
    fontSize: ['16px', null, null, '20px'],
    paddingLeft: ['10px', null, '18px', '18px'],
    height: ['25px', null, '29px', '29px'],
  })}
`;
