import { tv } from 'tailwind-variants';

import Cta from '../../../Cta';
import { withErrorBoundary } from '../../../ErrorBoundary';

type Link = {
  to: string;
  label: string;
};

export type MainNavigationDefaultProps = {
  navigation: Link[];
  title?: string;
};

const style = tv({
  slots: {
    link: 'footer-nav-link',
    list: 'footer-nav-list z-10',
    listItem: 'footer-nav-item',
    title: 'footer-nav-title',
  },
});

const MainNavigationDefault = ({ navigation, title }: MainNavigationDefaultProps) => {
  const { list, listItem, title: titleStyle, link } = style();

  return (
    <ul className={list()}>
      <span className={titleStyle()}>{title}</span>
      {navigation.map((item, idx) => (
        <li className={listItem()} key={`footernav-${item.to}-${idx}`}>
          <Cta className={link()} to={item.to} title={item.label}>
            {item.label}
          </Cta>
        </li>
      ))}
    </ul>
  );
};

export default withErrorBoundary(MainNavigationDefault, { componentName: 'MainNavigationDefault' });
