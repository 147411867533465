import { ChevronRightEvent } from '@arvesta-websites/icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import Media from '../../../../utils/Media';
import { Cta } from '../../../components';

// note: this is a duplicate for the styling in TopCard styling so reduntant
export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.categorySection.color};
  font-weight: ${({ theme }) => theme.categorySection.title?.fontWeight ?? 500};

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) =>
    Media({
      fontSize: [
        theme.categorySection.title?.fontSize?.mobile ?? '1rem', // 16px
        null,
        theme.categorySection.title?.fontSize?.tablet ?? '1.313rem', // 21px
        theme.categorySection.title?.fontSize?.desktop ?? '1.75rem', // 28px
      ],
      lineHeight: [
        theme.categorySection.title?.lineHeight?.mobile ?? '1.25rem', // 20px
        null,
        theme.categorySection.title?.lineHeight?.tablet ?? '1.688rem', // 27px
        theme.categorySection.title?.lineHeight?.desktop ?? '2.25rem', // 36px
      ],
    })}
`;

interface TruncatedComponentProps {
  lines?: string | string[];
}
export const CardTitle = styled(Title)<TruncatedComponentProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  white-space: break-spaces;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props?.lines ?? '2'};
`;

export const StyledImage = styled(GatsbyImage)`
  left: 0;
  top: 0;
  object-fit: cover;
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  transition: all 0.3s ease-in-out;

  > div {
    width: 100% !important;
  }

  ${Media({
    display: ['none', null, 'block'],
  })}

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1;

  &:hover {
    text-decoration: none;
  }
`;

export const CardContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  &:hover {
    text-decoration: none;
  }

  ${({ theme }) =>
    Media({
      alignItems: ['center', null, theme.categorySection?.alignment ?? 'flex-start'],
      minHeight: ['72px', null, '100px', '200px'],
      padding: ['1rem', null, '0.75rem', '0.75rem 1.5rem'],
    })}
`;

export const CardIcon = styled.img`
  width: 40px;
  height: 40px;

  ${Media({
    display: [null, null, 'none'],
  })}
`;

export const StyledChevron = styled(ChevronRightEvent)`
  align-self: flex-end;
  z-index: 1;
  flex-shrink: 0;
  opacity: ${({ theme }) => theme.categorySection.chevron.opacity || 0.9};

  & .iconCircle {
    fill: ${({ theme }) => theme.categorySection.chevron.fill};
  }

  & .iconArrow {
    stroke: ${({ theme }) => theme.categorySection.chevron.stroke};
  }
  &:hover {
    opacity: 1;
    & .iconCircle {
      fill: ${({ theme }) => theme.categorySection.chevron.hoverFill};
    }
    & .iconArrow {
      stroke: ${({ theme }) => theme.categorySection.chevron.hoverStroke};
    }
  }

  ${Media({
    alignSelf: ['center', null, 'flex-end'],
    height: [null, null, null, '32px'],
    width: [null, null, null, '32px'],
  })}
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(0deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
  transition: all 0.3s ease-in-out;

  ${Media({
    display: ['none', null, 'flex'],
  })}
`;

export const CardWrapper = styled(Cta)`
  position: relative;
  background-color: ${({ theme }) => theme.categorySection.background};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.categorySection.boxShadow};
  text-decoration: none;

  &:hover {
    text-decoration: none;

    ${Gradient} {
      height: 100%;
    }

    ${StyledImage} {
      transform: scale(1.05);
    }

    ${StyledChevron} {
      color: white;
      opacity: 1;
    }
  }
`;
